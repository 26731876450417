import { Injectable, NgModule } from "@angular/core";
import {
  Routes,
  RouterModule,
  DefaultUrlSerializer,
  UrlTree,
  UrlSerializer,
} from "@angular/router";
import { LoginComponent } from "./components/account/login/login.component";
import { UnautorizedComponent } from "./components/account/unautorized/unautorized.component";
import { DeliveryOrderPrintComponent } from "./components/manage-sales/deliveryOrder/delivery-order-print/delivery-order-print.component";
import { DenominationPrintComponent } from "./components/denomination/denomination-print/denomination-print.component";
import { PrintMvrInvoiceComponent } from "./components/manage-sales/invoice/print-mvr-invoice/print-mvr-invoice.component";
import { PrintPosInvoiceComponent } from "./components/manage-sales/invoice/print-pos-invoice/print-pos-invoice.component";
import { PrintUsdInvoiceComponent } from "./components/manage-sales/invoice/print-usd-invoice/print-usd-invoice.component";
import { MVRMemoPrintComponent } from "./components/manage-sales/memo/mvrmemo-print/mvrmemo-print.component";
import { USDMemoPrintComponent } from "./components/manage-sales/memo/usdmemo-print/usdmemo-print.component";
import { PrintMvrQuotationComponent } from "./components/manage-sales/quotations/print-mvr-quotation/print-mvr-quotation.component";
import { PrintUsdQuotationComponent } from "./components/manage-sales/quotations/print-usd-quotation/print-usd-quotation.component";
import { PrintMvrReceiptComponent } from "./components/manage-sales/receipt/print-mvr-receipt/print-mvr-receipt.component";
import { PrintUsdReceiptComponent } from "./components/manage-sales/receipt/print-usd-receipt/print-usd-receipt.component";
import { CostingPrintComponent } from "./components/manage-stocks/costing/costing-print/costing-print.component";
import { CostingProfitPrintComponent } from "./components/manage-stocks/costing/costing-profit-print/costing-profit-print.component";
import { ReceivingPrintComponent } from "./components/manage-stocks/receiving/receiving-print/receiving-print.component";
import { TansferOrderPrintComponent } from "./components/manage-transfers/transfer-order/tansfer-order-print/tansfer-order-print.component";
import { PrintLayoutComponent } from "./components/print-layout/print-layout.component";
import { BrandResolver } from "./resolver/brand.resolver";
import { CategoryResolver } from "./resolver/category.resolver";
import { CostingResolver } from "./resolver/costing.resolver";
import { CostingLineResolver } from "./resolver/costingLine.resolver";
import { CurrencyResolver } from "./resolver/currency.resolver";
import { DeliveryOrderResolver } from "./resolver/deliveryOrder.resolver";
import { DenominationResolver } from "./resolver/denomination.resolver";
import { DepositAccountResolver } from "./resolver/depositAccount.resolver";
import { InvoiceResolver } from "./resolver/invoice.resolver";
import { PaymentTermResolver } from "./resolver/paymentTerm.resolver";
import { ProductPromotionResolver } from "./resolver/productPromotion.resolver";
import { ReceiptResolver } from "./resolver/receipt.resolver";
import { ReceivingResolver } from "./resolver/receiving.resolver";
import { ReceivingLineResolver } from "./resolver/receivingLine.resolver";
import { SalesResolver } from "./resolver/sales.resolver";
import { TaxResolver } from "./resolver/tax.resolver";
import { TransferOrderResolver } from "./resolver/transferOrder.resolver";
import { TransferOrderLineResolver } from "./resolver/transferOrderLine.resolver";
import { UnitOfMeasureResolver } from "./resolver/unitOfMeasure.resolver";
import { VendorResolver } from "./resolver/vendor.resolver";
import { JobUSDPrintComponent } from "./components/manage-sales/job/job-usd-print/job-usd-print.component";
import { JobMVRPrintComponent } from "./components/manage-sales/job/job-mvr-print/job-mvr-print.component";
import { ModelResolver } from "./resolver/model.resolver";
import { AuthGuard } from "./core/services/auth-guard.service";
import { AuthService } from "./core/services/auth.service";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { Utilities } from "./core/models/utilities";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import { NotFoundComponent } from "./components/account/not-found/not-found.component";
import { CancelInterceptorService } from "./core/services/cancel-interceptor.service";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { DenominationComponent } from "./components/denomination/denomination.component";

@Injectable()
export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  parse(url: string): UrlTree {
    const possibleSeparators = /[?;#]/;
    const indexOfSeparator = url.search(possibleSeparators);
    let processedUrl: string;

    if (indexOfSeparator > -1) {
      const separator = url.charAt(indexOfSeparator);
      const urlParts = Utilities.splitInTwo(url, separator);
      urlParts.firstPart = urlParts.firstPart.toLowerCase();

      processedUrl = urlParts.firstPart + separator + urlParts.secondPart;
    } else {
      processedUrl = url.toLowerCase();
    }
    return super.parse(processedUrl);
  }
}

const routes: Routes = [
  {
    path: "",
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: { title: "Dashboard" },
  },
  { path: "login", component: LoginComponent, data: { title: "Login" } },

  {
    path: "stocks",
    canActivate: [AuthGuard],
    resolve: {
      category: CategoryResolver,
      brand: BrandResolver,
      tax: TaxResolver,
    },
    loadChildren: () =>
      import("./components/stock/stock.module").then((m) => m.StockModule),
  },
  //#module routes
  {
    path: "manage-customers",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./components/manage-customers/manage-customers.module").then(
        (m) => m.ManageCustomersModule
      ),
  },
  {
    path: "manage-stocks",
    canActivate: [AuthGuard],
    resolve: {
      category: CategoryResolver,
      model: ModelResolver,
      brand: BrandResolver,

      uom: UnitOfMeasureResolver,
      tax: TaxResolver,
      vendor: VendorResolver,
    },
    loadChildren: () =>
      import("./components/manage-stocks/manage-stocks.module").then(
        (m) => m.ManageStocksModule
      ),
  },
  {
    path: "manage-products",
    canActivate: [AuthGuard],
    resolve: {
      uom: UnitOfMeasureResolver,
      tax: TaxResolver,
    },
    loadChildren: () =>
      import("./components/manage-products/manage-products.module").then(
        (m) => m.ManageProductsModule
      ),
  },
  {
    path: "manage-transfers",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./components/manage-transfers/manage-transfers.module").then(
        (m) => m.ManageTransfersModule
      ),
  },
  {
    path: "manage-sales",
    canActivate: [AuthGuard],
    resolve: {
      brand: BrandResolver,
      tax: TaxResolver,
      currency: CurrencyResolver,
      paymentTerm: PaymentTermResolver,
    },
    loadChildren: () =>
      import("./components/manage-sales/manage-sales.module").then(
        (m) => m.ManageSalesModule
      ),
  },

  {
    path: "authorization",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./components/authorization/authorization.module").then(
        (m) => m.AuthorizationModule
      ),
  },
  {
    path: "reports",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./components/reports/reports.module").then(
        (m) => m.ReportsModule
      ),
  },
  //#end module routes
  {
    path: "config-settings",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./components/config-settings/config-settings.module").then(
        (m) => m.ConfigSettingsModule
      ),
  },
  {
    path: "accounts",
    canActivate: [AuthGuard],
    resolve: {
      depositAccount: DepositAccountResolver,
    },
    loadChildren: () =>
      import("./components/accounts/accounts.module").then(
        (m) => m.AccountsModule
      ),
  },
  {
    path: "approvals",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./components/approvals/approvals.module").then(
        (m) => m.ApprovalsModule
      ),
  },
  {
    path: "admin-settings",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./components/admin-settings/admin-settings.module").then(
        (m) => m.AdminSettingsModule
      ),
  },
  {
    path: "settings",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./components/settings/settings.module").then(
        (m) => m.SettingsModule
      ),
  },

  //--Denominations--//
  {
    path: "denomination",
    component: DenominationComponent,
    data: { title: "Denomination" },
  },
  // PRINT ROUNTING
  {
    path: "print",
    outlet: "print",
    component: PrintLayoutComponent,
    children: [
      {
        path: "mvrinvoice-print/:id",
        component: PrintMvrInvoiceComponent,
        resolve: {
          invoice: InvoiceResolver,
          tax: TaxResolver,
        },
      },
      {
        path: "posinvoice-print/:id",
        component: PrintPosInvoiceComponent,
        resolve: {
          invoice: InvoiceResolver,
          tax: TaxResolver,
        },
      },
      {
        path: "usdinvoice-print/:id",
        component: PrintUsdInvoiceComponent,
        resolve: {
          invoice: InvoiceResolver,
          tax: TaxResolver,
          currency: CurrencyResolver,
        },
      },
      {
        path: "mvrquotations-print/:id",
        component: PrintMvrQuotationComponent,
        resolve: {
          sales: SalesResolver,
          tax: TaxResolver,
        },
      },
      {
        path: "usdquotations-print/:id",
        component: PrintUsdQuotationComponent,
        resolve: {
          sales: SalesResolver,
          tax: TaxResolver,
          currency: CurrencyResolver,
        },
      },
      {
        path: "mvrmemo-print/:id",
        component: MVRMemoPrintComponent,
        resolve: {
          sales: SalesResolver,
          tax: TaxResolver,
        },
      },
      {
        path: "usdmemo-print/:id",
        component: USDMemoPrintComponent,
        resolve: {
          sales: SalesResolver,
          tax: TaxResolver,
          currency: CurrencyResolver,
        },
      },

      {
        path: "job-mvr-print/:id",
        component: JobMVRPrintComponent,
        resolve: {
          sales: SalesResolver,
          tax: TaxResolver,
        },
      },
      {
        path: "job-usd-print/:id",
        component: JobUSDPrintComponent,
        resolve: {
          sales: SalesResolver,
          tax: TaxResolver,
          currency: CurrencyResolver,
        },
      },

      {
        path: "mvrreceipt-print/:id",
        component: PrintMvrReceiptComponent,
        resolve: {
          payment: ReceiptResolver,
          tax: TaxResolver,
        },
      },
      {
        path: "usdreceipt-print/:id",
        component: PrintUsdReceiptComponent,
        resolve: {
          payment: ReceiptResolver,
          tax: TaxResolver,
          currency: CurrencyResolver,
        },
      },
      {
        path: "denomination-print/:id",
        component: DenominationPrintComponent,
        resolve: {
          denomination: DenominationResolver,
        },
      },
      {
        path: "costing-print/:id",
        component: CostingPrintComponent,
        resolve: {
          costing: CostingResolver,
          costingLine: CostingLineResolver,
          tax: TaxResolver,
        },
      },
      {
        path: "costing-profit-print/:id",
        component: CostingProfitPrintComponent,
        resolve: {
          costing: CostingResolver,
          costingLine: CostingLineResolver,
          tax: TaxResolver,
        },
      },
      {
        path: "receiving-print/:id",
        component: ReceivingPrintComponent,
        resolve: {
          receiving: ReceivingResolver,
          receivingLine: ReceivingLineResolver,
          tax: TaxResolver,
        },
      },
      {
        path: "transferOrder-print/:id",
        component: TansferOrderPrintComponent,
        resolve: {
          transferOrder: TransferOrderResolver,
          transferOrderLine: TransferOrderLineResolver,
          tax: TaxResolver,
        },
      },
      {
        path: "deliveryOrder-print/:id",
        component: DeliveryOrderPrintComponent,
        resolve: {
          deliveryOrder: DeliveryOrderResolver,
        },
      },
    ],
  },

  // DEFAULT ROUNTING
  { path: "home", redirectTo: "/", pathMatch: "full" },
  {
    path: "**",
    component: NotFoundComponent,
    data: { title: "Page Not Found" },
  },
  {
    path: "unautorized",
    component: UnautorizedComponent,
    data: { title: "Un-Autorized" },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    AuthService,
    AuthGuard,
    // { provide: UrlSerializer, useClass: LowerCaseUrlSerializer },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CancelInterceptorService,
      multi: true,
    },
  ],
})
export class AppRoutingModule {}
