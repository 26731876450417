import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../core/services/auth.service";
import { StaffDiscountExemption } from "../models/staffDiscountExemption";
import { StaffDiscountExemptionService } from "../services/staffDiscountExemption.service";

@Injectable()
export class StaffDiscountExemptionResolver
  implements Resolve<StaffDiscountExemption[]>
{
  constructor(
    private service: StaffDiscountExemptionService,
    private authService: AuthService
  ) {}

  resolve(): Observable<StaffDiscountExemption[]> {
    if (this.authService.isLoggedIn) {
      if (this.authService.currentBusiness) {
        return this.service.getStaffDiscountExemptionByBusinessTypeId(
          this.authService.currentBusiness.businessTypeId
        );
      }
    } else {
      return null;
    }
  }
}
